import { render, staticRenderFns } from "./subject-lecturer-survey.vue?vue&type=template&id=4b6764bb&scoped=true"
import script from "./subject-lecturer-survey.vue?vue&type=script&lang=js"
export * from "./subject-lecturer-survey.vue?vue&type=script&lang=js"
import style0 from "./subject-lecturer-survey.vue?vue&type=style&index=0&id=4b6764bb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b6764bb",
  null
  
)

export default component.exports