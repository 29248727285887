<template>
  <div class="bg-blue py-3" v-show="question.isVisible==1">
      <div class="form-group mx-3 text-left "  :style="forRedData.includes(question.survey_questionID)?'background-color:red':'background-color:transparent'">
        <label :for="'question-'+question.survey_questionID">
          <p>{{ helpers.getLocalizedField(question, 'question') }}</p>
        </label>
        <textarea   class="form-control"
                  rows="7"
                  v-model="answer"
                  @blur="handleAnswer"
                  @click="changeContent()"
                  :id="'question-'+question.survey_questionID"></textarea>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'open-question',

  props: ['question', 'answerData'],

  data() {
    return {
      answer: this.question.required==1?'!':'',
      forRedData:[]
      
    }
  },

  created() {
    if(this.answerData) this.answer = this.answerData.answer;
  },

  mounted(){

    if(this.question.required==1){
      this.$emit('answered', this.question.survey_questionID, this.answer);
    }

  },
  watch:{

      forRed(val){



        if(val.length>0){
          
          this.forRedData=val

        }

      }

      },

      computed:{

      ...mapGetters({
          forRed:'survey/getForRed'
      })

      },

  methods: {
    handleAnswer() {
      
      this.$emit('answered', this.question.survey_questionID, this.answer);

      if(this.answer.length==0 && this.question.required==1){

         this.answer='!'
      }

    },
    changeContent(){
       
      if(this.answer.length==0 && this.question.required==1){
          this.answer='!'
      }else {
        if(this.answer!='!'){
            this.answer=this.answer
        }else {
            this.answer=''
        }
           

        
      }

      
    } 
  }
};
</script>

<style scoped>
p{
    color: #494949;
    font-size: 1.5rem;
    line-height: 2.6rem;
    margin-bottom: 13px;
}
textarea{ border: 1px solid var(--form-border);}
.bg-blue{
    background-color: rgba(85, 124, 132, 0.09);
    border-radius: 8px;
}
</style>
